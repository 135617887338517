//
// Nav
//

// Line tabs
.nav-line-tabs {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: var(--kt-border-color);
	font-family: $font-family-roboto !important;
	font-size: $font-size-l;
	font-weight: 500;
	line-height: 21px;

	.nav-item {
		margin-bottom: -1px;

		// Base link
		.nav-link {
			font-family: $font-family-roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color:var(--kt-text-muted);
		}

		// First Item
		&:first-child {
			.nav-link {
				margin-left: 0;
			}
		}

		// Last Item
		&:last-child {
			.nav-link {
				margin-right: 0;
			}
		}
	}

	// Active & Hover States
	.nav-item .nav-link.active,
	.nav-item.show .nav-link,
	.nav-item .nav-link:hover:not(.disabled) {
		background-color: transparent;
		border: 0;
		border-bottom: 1px solid var(--kt-primary);
		transition: $transition-link;
	}

	// 2x Line
	&.nav-line-tabs-2x {
		border-bottom-width: 2px;

		.nav-item {
			margin-bottom: -2px;

			.nav-link {
				border-bottom-width: 2px;
			}
		}

		// Active & Hover States
		.nav-item .nav-link.active,
		.nav-item.show .nav-link,
		.nav-item .nav-link:hover:not(.disabled) {
			border-bottom-width: 2px;
		}
	}
}
