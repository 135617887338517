@import "./../sass/components/variables.custom";

.conteinerTable {
  max-height: 836px;
  overflow-y: auto;
}

.containerChartModal {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

// custom select
[data-theme="dark"] {
  .css-1insrsq-control {
    font-weight: 400 !important;
    color: var(--kt-input-color) !important;
    background-color: var(--kt-input-bg) !important;
    border: 2px solid var(--kt-input-border-color) !important;
    opacity: 0.5;
  }

  .css-12jo7m5 {
    font-weight: 400 !important;
    color: var(--kt-input-color) !important;
    background-color: var(--kt-input-bg) !important;
    border: 2px solid var(--kt-input-border-color) !important;
    border-radius: 2px !important;
  }

  .css-xb97g8 {
    border-radius: 2px !important;
    font-weight: 400 !important;
    color: var(--kt-input-color) !important;
    background-color: var(--kt-input-bg) !important;
    border: 2px solid var(--kt-input-border-color) !important;
  }
}

.css-3iigni-container {
  border: none !important;
}

.css-14el2xx-placeholder {
  color: var(--kt-input-color) !important;
}

.css-qc6sy-singleValue {
  color: var(--kt-input-color) !important;
}

.css-1pndypt-Input {
  color: var(--kt-input-color) !important;
}

.css-26l3qy-menu {
  border-radius: 0.625rem !important;
  font-weight: 400 !important;
  color: var(--kt-input-color) !important;
  background-color: var(--kt-input-bg) !important;
  border: 1px solid var(--kt-input-border-color) !important;
}

.css-1pahdxg-control {
  border-radius: 0.625rem !important;
  font-weight: 400 !important;
  color: var(--kt-input-color) !important;
  background-color: var(--kt-input-bg) !important;
  border: 1px solid var(--kt-input-border-color) !important;
}

.css-1s2u09g-control {
  border-radius: 0.625rem !important;
  font-weight: 400 !important;
  color: var(--kt-input-color) !important;
  background-color: var(--kt-input-bg) !important;
  border: 1px solid var(--kt-input-border-color) !important;
  box-shadow: none !important;
}
// end custom select

.sinPading {
  padding: 0px !important;
}

.sinPaddingTop {
  padding-top: 0px !important;
}

.sinPaddingBotton {
  padding-bottom: 0px !important;
}

.sinPading {
  padding-left: 5px !important;
  padding-right: 0px !important;
}

.load-screen {
  position: fixed;
  background-color: rgba(238, 238, 238, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
}

.global-loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// ---------------------------------- estilos maqueta nueva
.LogoLogin {
  height: 128px;
  width: 128px;
}

.form-control {
  font-family: $font-family-roboto !important;
}

.form-label {
  font-family: $font-family-roboto !important;
  font-size: $font-size-xl;
  font-weight: 400 !important;
  letter-spacing: 0;
  min-height: 16px;
  // width: 54px;
  line-height: normal;
}

::placeholder {
  font-family: $font-family-roboto !important;
  font-style: italic;
}

h1 {
  font-family: $font-family-roboto !important;
  font-size: $font-size-m;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0;
  text-align: center;
}

.cajaLogin {
  background-color: #f6f8fa !important;
  border: 0.5px none !important;
  border-radius: 4px;
  box-shadow: 0px 2px 4px #00000a;
}

.svg-icon-custom {
  background-color: $color-icon;
  border: 1px none;
  border-radius: 5px;
  height: 27px;
  width: 28px;
}

.row.react-bootstrap-table-pagination {
  width: 99% !important;
}

[data-theme="dark"] {
  .logo404 {
    content: url("../../../../public/media/illustrations/sketchy-1/18_dark.svg");
  }

  .dotLogn {
    border-radius: 50%;
    height: 50px;
    width: 52px;
    background-color: #151521;
    box-shadow: 0px 2px 6px #000040;
    border: 1px none;
    margin-bottom: 5px;
  }

  .dot {
    border-radius: 50%;
    height: 25px;
    width: 27px;
    background-color: #151521;
    box-shadow: 0px 2px 6px #000040;
    border: 1px none;
    margin-bottom: 5px;
  }

  .logoGovms {
    width: 167px;
    height: 32px;
    left: 600px;
    top: 691px;
    content: url("../../../../public/media/logos/logo-oscuro-govms.png");
  }

  .fondoLogin {
    background-image: url("../../../../public//media/illustrations/sketchy-1/14_noche.png");
  }
}

[data-theme="light"] {
  .logo404 {
    content: url("../../../../public/media/illustrations/sketchy-1/18.svg");
  }

  .dotLogn {
    border-radius: 50%;
    height: 50px;
    width: 52px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px #000040;
    border: 1px none;
    margin-bottom: 5px;
  }

  .dot {
    border-radius: 50%;
    height: 25px;
    width: 27px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px #000040;
    border: 1px none;
    margin-bottom: 5px;
  }

  .logoGovms {
    width: 167px;
    height: 32px;
    left: 600px;
    top: 691px;
    content: url("../../../../public/media/logos/logo_Govms.png");
  }

  .fondoLogin {
    background-image: url("../../../../public//media/illustrations/sketchy-1/14_dia.png");
  }
}

.MoradoOscuro {
  background-color: #161696 !important;
}

.perfilUsuario {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: right;
}

.centroUsuario {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: right;
}

.textoInformativo {
  background: #fff3cd;
  border-radius: 6px;
  max-width: 70%;
  height: auto;
  left: 886px;
  top: 248px;
  padding: 10px;

  span {
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #664d03;
  }
}

.dotInformativo {
  height: 19px;
  width: 19px;
  background-color: #fff3cd;
  box-shadow: 0px 2px 6px #000040;
  border-radius: 50%;
  display: inline-block;
  border: 1px none;
  margin-bottom: 5px;
}

.sub-title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  color: var(--bs-indicador) !important;
  font-family: "Roboto";
  font-weight: 500;
  // font-size: 13px;
  line-height: 15px;
}

h3 {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

label {
  font-family: "Roboto" !important;
  font-style: normal;
}

.centerTitle {
  th {
    text-align: center;
  }
}
